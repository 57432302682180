.share_popup_modal {
  width: 450px !important;
  min-height: 100vh;
  align-items: center;
  display: flex;
  .icon_circle {
    width: 60px;
    height: 60px;
    opacity: 0.75;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    cursor: pointer;
  }
  .copy-clipboard-input {
    padding: 8px;
    width: 100%;
    outline: none;
  }
  .copy-btn {
    width: 100%;
    outline: none;
    border: none;
    background-color: #1091ff;
    color: white;
    padding: 8px;
  }
  .fa{
    cursor: pointer;
  }
}
