.content_1{
    font-size: 20px;
    margin-top: 2rem;
    margin-bottom: 1rem;
    font-weight: bold;
}

.content-style{
    background: white;
    box-shadow: 0px 0px 4px 4px #C2C6CB40;
}

.content {
  margin-left: 220px; /* Adjust based on menu width */
  padding: 20px;
  width: calc(100% - 220px);
}

.content-section {
  padding: 1rem;
}


.nomination_popup_modal {
  width: 450px;
  min-height: 100vh;
  align-items: center;
  display: flex;
  .newsletter_popup_btn {
    width: 100%;
    height: 45px;
    background: white;
    color: white;
    font-size: 16px;
    font-weight: 15px;
    border: none;
  }
  input {
    padding: 11px !important;
  }
  .subscribe-text {
    margin-top: 20px;
    text-align: center;
    margin-bottom: 10px;
  }
  .footer_logo_tint {
    height: 60px !important;
  }
}

.modal_nomination_content {
  background-color: #fff;
}

.custom-error-brands{
  font-size: 12px;
    margin-left: 3px;
    color: red;
}

.custom-error-checkbox-brands{
  font-size: 12px;
  margin-left: 16px;
  margin-top: -16px;
  color: red;
}

.brands-form input{
  box-shadow: 0px 0px 4px 4px #C2C6CB40;
  height: 37px !important;
  background: none;
    width: 100%;

} 


.brands-form select{
  box-shadow: 0px 0px 4px 4px #C2C6CB40;
  height: 37px !important;
  background: none;
    width: 100%;
    border: 0px !important;

} 

.content-section-brand{
  padding: 0.5rem !important;
}

.topic-brands-section{
  text-align: center;
    margin-left: 0.7rem;
    display: flex;
    background-color: aliceblue;
    width: 100%;
    justify-content: center;
    margin-top: 15px;
    padding: 5px;
    border-radius: 10px;
}

.content_1_{
  display: flex;
  justify-content: center;
  font-size: 15px;
}